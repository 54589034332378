import React from 'react';

interface PropertySearchControlsProps {
  searchParams: {
    provinceId: string;
    town: string;
    suburb: string;
    street: string;
    streetNumber: string;
    unitNumber: string;
  };
  onSearchParamsChange: (key: string, value: string) => void;
}

const PropertySearchControls: React.FC<PropertySearchControlsProps> = ({
  searchParams,
  onSearchParamsChange
}) => {

    const provinces = [
        {
          provinceId: 1,
          province: "Eastern Cape",
          capital: "Bisho",
        },
        {
          provinceId: 2,
          province: "Free State",
          capital: "Bloemfontein",
        },
        {
          provinceId: 3,
          province: "Gauteng",
          capital: "Johannesburg",
        },
        {
          provinceId: 4,
          province: "Kwazulu-Natal",
          capital: "Pietermaritzburg",
        },
        {
          provinceId: 5,
          province: "Limpopo",
          capital: "Polokwane",
        },
        {
          provinceId: 6,
          province: "Mpumalanga",
          capital: "Nelspruit",
        },
        {
          provinceId: 7,
          province: "North-West",
          capital: "Mafikeng",
        },
        {
          provinceId: 8,
          province: "Northern Cape",
          capital: "Kimberley",
        },
        {
          provinceId: 9,
          province: "Western Cape",
          capital: "Cape Town",
        },
      ];


  return (
    <div className='col-md-12'>
      <div className="row">
      <div className="form-group col-md-4">
        <label htmlFor="province">Province</label>
        <select
          className="form-control"
          id="province"
          value={searchParams.provinceId}
          onChange={(e) => onSearchParamsChange("provinceId", e.target.value)}
        >
          {provinces.map((province) => (
            <option key={province.provinceId} value={province.provinceId}>
              {province.province}
            </option>
          ))}
        </select>
      </div>
      </div>
      <div className="row">
      <div className="form-group col-md-4">
        <label htmlFor="town">Town</label>
        <input
          type="text"
          className="form-control"
          id="town"
          placeholder="Town"
          value={searchParams.town}
          onChange={(e) => onSearchParamsChange("town", e.target.value)}
        />
      </div>
      </div>
      <div className="row">
      <div className="form-group col-md-4">
        <label htmlFor="suburb">Suburb</label>
        <input
          type="text"
          className="form-control"
          id="suburb"
          placeholder="Suburb"
          value={searchParams.suburb}
          onChange={(e) => onSearchParamsChange("suburb", e.target.value)}
        />
      </div>
      </div>
      <div className="row">
      <div className="form-group col-md-4">
        <label htmlFor="street">Street (Exclude type: street, road, avenue, close, etc.)</label>
        <input
          type="text"
          className="form-control"
          id="street"
          placeholder="Street"
          value={searchParams.street}
          onChange={(e) => onSearchParamsChange("street", e.target.value)}
        />
      </div>
      </div>
      <div className="row">
      <div className="form-group col-md-4">
        <label htmlFor="streetNumber">Street Number</label>
        <input
          type="text"
          className="form-control"
          id="streetNumber"
          placeholder="Street Number"
          value={searchParams.streetNumber}
          onChange={(e) => onSearchParamsChange("streetNumber", e.target.value)}
        />
      </div>
      </div>
      <div className="row">
      <div className="form-group col-md-4">
        <label htmlFor="unitNumber">Unit No</label>
        <input
          type="text"
          className="form-control"
          id="unitNumber"
          placeholder="Unit No"
          value={searchParams.unitNumber}
          onChange={(e) => onSearchParamsChange("unitNumber", e.target.value)}
        />
      </div>
      </div>
    </div>
  );
};

export default PropertySearchControls;
